import { Helmet } from "react-helmet";
import PublicatieCard from "../components/publicatieCard";

const publicaties: Publicatie[] = [
  {
    image: "/assets/publicaties/Nieuwsbrief december 2024.png",
    title: "Nieuwsbrief december 2024",
    fileName: "Nieuwsbrief december 2024.pdf",
  },
  {
    image: "/assets/publicaties/Campagne, brief oktober.png",
    title: "Campagne, brief oktober",
    fileName: "Campagne, brief oktober.pdf",
  },
  {
    image: "/assets/publicaties/Campagne, brochure programma en kandidaten.png",
    title: "Campagne, brochure programma en kandidaten",
    fileName: "Campagne, brochure programma en kandidaten.pdf",
  },

  {
    image: "/assets/publicaties/campagne, flyer Luc, Sofie en Annick.png",
    title: "campagne, flyer Luc, Sofie en Annick",
    fileName: "campagne, flyer Luc, Sofie en Annick.pdf",
  },
  {
    image: "/assets/publicaties/campagne, flyer jongeren.png",
    title: "campagne, flyer jongeren",
    fileName: "campagne, flyer jongeren.pdf",
  },
  {
    image: "/assets/publicaties/campagne, flyer Tjén, Mathijs en Fee.png",
    title: "campagne, flyer Tjén, Mathijs en Fee",
    fileName: "campagne, flyer Tjén, Mathijs en Fee.pdf",
  },
  {
    image: "/assets/publicaties/Campagne, brief september.png",
    title: "Campagne, brief september",
    fileName: "Campagne, brief september.pdf",
  },
  {
    image: "/assets/publicaties/Campagne, brief augustus.png",
    title: "Campagne, brief augustus",
    fileName: "Campagne, brief augustus.pdf",
  },
  {
    image: "/assets/publicaties/Folder juni 2024.png",
    title: "Juni 2024",
    fileName: "Folder juni 2024.pdf",
  },
  {
    image: "/assets/publicaties/Folder juni 2023.png",
    title: "Juni 2023",
    fileName: "Folder juni 2023.pdf",
  },
  {
    image: "/assets/publicaties/Folder december 2023.png",
    title: "December 2023",
    fileName: "Folder december 2023.pdf",
  },
];

export type Publicatie = {
  image: string;
  title: string;
  fileName: string;
};

export default function Publicaties() {
  return (
    <article className="background-figures flex w-full items-center bg-yellow">
      <Helmet>
        <title>Publicaties | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex flex-col justify-evenly gap-8 px-4  py-8">
        <div
          id="publicatie-container"
          className=" flex w-full flex-wrap justify-evenly gap-8 "
        >
          {publicaties.slice(0, 3).map((publicatie: Publicatie) => (
            <PublicatieCard publicatie={publicatie} />
          ))}{" "}
        </div>
        <div
          id="publicatie-container"
          className=" flex w-full  flex-wrap   justify-evenly gap-8 "
        >
          {publicaties.slice(3).map((publicatie: Publicatie) => (
            <PublicatieCard publicatie={publicatie} />
          ))}
        </div>
      </section>
    </article>
  );
}
